<template>
  <div class="partition-table">
    <ics-table-inner v-loading="loading.list" title="金融产品" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" :hide-column-btn="true" @changePageNum="changePageNum">
      <template slot="table-columns-before">
        <el-table-column fixed="left" label="序号" type="index" :index="indexMethod" />
      </template>
    </ics-table-inner>
  </div>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
export default {
  name: 'IcsProductInner',
  mixins: [basePageListMixin],
  props: {
    capCode: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showFoldSearch: false,
      columnOption: [
        { label: '产品编号', prop: 'productCode', formatter: this.utils.isEffective, minWidth: 170 },
        { label: '产品名称', prop: 'productName', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '产品类型', prop: 'productFinancing', formatter: (row, col, cell) => this.utils.statusFormat(Number(cell), 'productType'), minWidth: 140 },
        { label: '资金方', prop: 'capName', formatter: this.utils.isEffective, minWidth: 170 },
        { label: '状态', prop: 'state', formatter: (row, col, cell) => this.utils.statusFormat(String(cell), 'productState'), minWidth: 100 },
        { label: '审批状态', prop: 'approvalStatus', formatter: (row, col, cell) => this.utils.statusFormat(cell, 'productAuditStatus'), minWidth: 100 },
        { label: '创建时间', prop: 'createdAt', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '使用次数', prop: 'crdNumber', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '累计贷款额度(元)', prop: 'hisUseAmount', formatter: this.utils.tableMoneyFormat, minWidth: 140 },
      ]
    }
  },
  watch: {},
  created () {
  },
  methods: {
    getList () {
      this.loading.list = true
      this.searchForm.capCode = this.capCode
      this.api.product.product.productPageList(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    }
  }
}
</script>

<style scoped>

</style>
