<template>
  <el-main>
    <page-title show-back-btn />
    <div v-loading="loading.detail" class="partition-area">
      <el-form label-width="240px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="资金方基本信息">
          <el-col :span="12">
            <el-form-item label="资金方名称">
              <ics-item-inner :prop="appForm.capRel.capName" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="资金方编码">
              <ics-item-inner :prop="appForm.capRel.capCode" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="统一社会信用代码">
              <ics-item-inner :prop="appForm.capRel.capSocialNo" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态">
              <ics-item-inner :prop="appForm.capRel.enabled" :format="(val)=>utils.statusFormat(Number(val), 'enableStatus')" />
            </el-form-item>
          </el-col>
        </ics-page-inner>
        <ics-page-inner title="资金信息">
          <el-row :gutter="40" style="margin-left: 32px;">
            <el-col :span="5">
              <div class="show box flex">
                产品数量
              </div>
              <div class="show2 flex">
                {{ appForm.capStat.productNumber }}
              </div>
            </el-col>
            <el-col :span="5">
              <div class="show box flex">
                累计授信融资方数
              </div>
              <div class="show2 flex">
                {{ utils.moneyFormat(appForm.capStat.customerCreditNum) }}
              </div>
            </el-col>
            <el-col :span="6" style="margin-left: 25px;">
              <div class="show box flex">
                融资方累计授信
              </div>
              <div class="show2 flex">
                {{ utils.moneyFormat(appForm.capStat.customerCreditAmount) }}
              </div>
            </el-col>
            <el-col :span="6" style="margin-left: 26px;">
              <div class="show box flex">
                累计授信笔数
              </div>
              <div class="show2 flex">
                {{ utils.moneyFormat(appForm.capStat.creditNum) }}
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="80" style="margin-left: 31px;">
            <el-col :span="5">
              <div class="show box flex">
                累计放款
              </div>
              <div class="show2 flex">
                {{ utils.moneyFormat(appForm.capStat.loanAmount) }}
              </div>
            </el-col>
            <el-col :span="5" style="margin-left: -9px;">
              <div class="show box flex">
                累计收款
              </div>
              <div class="show2 flex">
                {{ utils.moneyFormat(appForm.capStat.collectAmount) }}
              </div>
            </el-col>
            <el-col :span="6" style="margin-left: 25px;">
              <div class="show box flex">
                实时放款
              </div>
              <div class="show2 flex">
                {{ utils.moneyFormat(appForm.capStat.currLoanAmount) }}
              </div>
            </el-col>
            <el-col :span="6" style="margin-left: 18px;">
              <div class="show box flex">
                待还款
              </div>
              <div class="show2 flex">
                {{ utils.moneyFormat(appForm.capStat.waitCollectAmount) }}
              </div>
            </el-col>
          </el-row>
        </ics-page-inner>
        <div class="area-title" />
        <div class="tabs-inner">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="企业工商信息" name="companyInfo">
              <ics-company-inner page-title="企业工商信息" :info-detail="appForm.firm" />
            </el-tab-pane>
            <el-tab-pane label="业务覆盖城市" name="second">
              <ics-page-inner title="业务覆盖城市">
                <ics-city-inner ref="city" :city-info.sync="cityList" :view-city="true" />
              </ics-page-inner>
            </el-tab-pane>
            <el-tab-pane label="金融产品" name="product">
              <ics-product-inner :cap-code="capCode" />
            </el-tab-pane>
            <el-tab-pane label="授信信息" name="credit">
              <ics-credit-inner :cap-code="$route.query.capCode" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-form>
    </div>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsCompanyInner from '@/components/company-inner'
import IcsCityInner from '@/components/city-inner'
import IcsCreditInner from './components/credit-inner'
import IcsProductInner from './components/product-inner'
export default {
  components: { IcsCompanyInner, IcsCityInner, IcsCreditInner, IcsProductInner },
  mixins: [routeEnterMixin],
  data () {
    return {
      capCode: this.$route.query.capCode,
      activeName: 'companyInfo',
      cityList: [],
      appForm: {
        capRel: {},
        capStat: {}
      },
      loading: {
        detail: false
      }
    }
  },
  created () {
    if (this.capCode) {
      this.getDetail()
    }
  },
  methods: {
    getDetail() {
      this.loading.detail = true
      this.api.company.capital.getCapDetail({ capCode: this.capCode }).then(result => {
        const data = result.data.data || {}
        let regAddr = []
        let workAddr = []
        if (data.firm.regAddr) {
          regAddr = data.firm.regAddr.split(',')
          data.firm.usEnterpriseAddress = [regAddr[0], regAddr[1], regAddr[2], data.firm.regAddrDetail]
        }
        if (data.firm.workAddr) {
          workAddr = data.firm.workAddr.split(',')
          data.firm.dsEnterpriseAddress = [workAddr[0], workAddr[1], workAddr[2], data.firm.workAddrDetail]
        }
        const cityKeys = []
        data.cityList.forEach(item => {
          cityKeys.push(item.areaCode)
        })
        this.cityList = cityKeys
        this.appForm = data || {}
      }).finally(() => {
        this.loading.detail = false
      })
    },
    handleClick () {

    }
  }
}
</script>

<style scoped>
.show{
  line-height:60px;
  font-size:15px;
  font-weight: bold;
}
.show2{
  line-height:80px;
  font-size:30px;
  font-weight: bold;
  /* font-family:'Times New Roman', Times, serif; */
  color:cornflowerblue;
}
.box{
  background-image: linear-gradient(180deg, #C9E3F6 0%, #C9E3F6 26%);
  box-shadow: 0 1px 2px 0 rgba(201,227,247,0.60);
  border-radius: 8px;
}
</style>
