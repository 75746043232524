<template>
  <div class="partition-table">
    <ics-table-inner v-loading="loading.list" title="授信信息" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" :hide-column-btn="true" @changePageNum="changePageNum">
      <template slot="table-columns-before">
        <el-table-column fixed="left" label="序号" type="index" :index="indexMethod" />
        <el-table-column property="customerName" label="企业名称" show-overflow-tooltip width="120" />
        <el-table-column label="授信额度" width="120" align="center">
          <template slot-scope="scope">
            <span>{{ utils.moneyFormat(scope.row.passAmount ? scope.row.passAmount : scope.row.applyAmount) }}</span>
          </template>
        </el-table-column>
      </template>
    </ics-table-inner>
  </div>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
export default {
  name: 'IcsCreditInner',
  mixins: [basePageListMixin],
  props: {
    capCode: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showFoldSearch: false,
      columnOption: [
        { label: '关联产品', prop: 'productName', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '用信额度(元)', prop: 'useAmount', formatter: this.utils.tableMoneyFormat, minWidth: 140 },
        { label: '剩余可用授信(元)', prop: 'surplusAmount', formatter: this.utils.tableMoneyFormat, minWidth: 140 },
        { label: '授信期限', prop: 'effectiveEnd', formatter: this.utils.isEffective, minWidth: 140 },
      ]
    }
  },
  watch: {},
  created () {
  },
  methods: {
    getList () {
      this.loading.list = true
      this.searchForm.capCode = this.capCode
      this.api.company.capital.getCreditPage(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    }
  }
}
</script>

<style scoped>

</style>
